@grey-light        : #F4F4F4;
@grey              : #E8E8E8;
@grey-dark         : #CBCBCB;
@anthracite        : #343434;
@marine            : #0C2447;
@marine-light      : #123154;
@orange            : #FCC200;
@green             : #008C50;
@red               : #C7063E;
@purple            : #B71570;
@sky               : #0085C6;
@ochre-light       : #FAF7F2;
@white             : #FFFFFF;
@ochre             : #AA9D8F;
@anthracite-light  : #999999;
@facebook          : #415399;
@facebook-light    : #4E64B6;
@twitter           : #419FD6;
@twitter-light     : #4DB5FA;
@google-plus       : #D53C32;
@google-plus-light : #E54A36;
@linkedin          : #0077B5;
@linkedin-light    : #00A0DC;
@cobalt-blue 	   : #0a3859;
@fog               : rgba(255,255,255,0.85);
@accent            : #f36e21;
@none              : rgba(255,255,255,0);
@universitas-bg    : #F3F3F3;  //243, 243, 243
@ami               : #fab500;  //250 181 0
@agef              : #702283;
@baurecht		   : #fddb04;
@aref		   	   : #d31920;  // 211 25 32
@fmentoring		   : #B1CB31;  // 177/203/49
@regard			   : #DC861C;  // 220/134/28

/**
 * Map a background color to font color and define
 * hover color (does not work well with very dark colors)
 * @param  {string} @name  Name of the background-color variable
 * @param  {string} @color Name of the font color variable
 * @return {undefined}
 */
.map(@name, @color) {
	.bg-@{name} {
		background: @@name;
		color: @color;
		&.hover:hover { background: darken(@@name, 5%); }
	}
	.color-@{name} { color: @@name; }
}

/**
 * Make branded color mappings
 * @param  {string} @name  Name of the background-color variable
 * @param  {string} @color Name of the font color variable
 * @return {undefined}
 */
.brand(@name, @color) {
	.@{name} {
		.bg-branded {
			background: @@name;
			color: @color;
			&.hover:hover { background: darken(@@name, 5%); }
		}
		.color-branded { color: @@name; }
	}
}

/**
 * Background color with current brand color.
 * @param  {boolean} @color: false         Style font color or not?
 * @param  {boolean} @hover: false         Add hover styles?
 * @return {undefined}
 */
.branded-background(@color: false, @hover: false) {
	background: @marine;
	.anthracite & { background: @anthracite; }
	.marine & { background: @marine; }
	.orange & { background: @orange; }
	.green & { background: @green; }
	.red & { background: @red; }
	.purple & { background: @purple; }
	.sky & { background: @sky; }
	.ami & { background: @ami; }
	.agef & { background: @agef; }
	.baurecht & { background: @baurecht; }
	.aref & { background: @aref; }
	.fmentoring & { background: @fmentoring; }
	.regard & { background: @regard; }
	
	// Add font color styles as well
	& when (@color = true) {
		color: white;
		.anthracite & { color: white; }
		.marine & { color: white; }
		.orange & { color: @anthracite; }
		.green & { color: white; }
		.red & { color: white; }
		.purple & { color: white; }
		.sky & { color: white; }
		.ami & {color: white; }
		.agef & {color: white; }
		.baurecht & { color: @anthracite; }
		.aref & { color: white; }
		.fmentoring & { color: white; }
		.regard & { color: white; }
	}
	
	// Add hover styles as well
	& when (@hover = true) {
		&:hover {
			background: darken(@marine, 5%);
			.anthracite & { background: darken(@anthracite, 5%); }
			.marine & { background: darken(@marine, 5%); }
			.orange & { background: darken(@orange, 5%); }
			.green & { background: darken(@green, 5%); }
			.red & { background: darken(@red, 5%); }
			.purple & { background: darken(@purple, 5%); }
			.sky & { background: darken(@sky, 5%); }
			.ami & { background: darken(@ami, 5%); }
			.agef & { background: darken(@agef, 5%); }
			.baurecht & { background: darken(@baurecht, 5%); }
			.aref & { background: darken(@aref, 5%); }
			.fmentoring & { background: darken(@fmentoring, 5%); }
			.regard & { background: darken(@regard, 5%); }
		}
	}
}

.dark-branded-background() {
	background: darken(@marine, 5%);
	.anthracite & { background: darken(@anthracite, 5%); }
	.marine & { background: darken(@marine, 5%); }
	.orange & { background: darken(@orange, 5%); }
	.green & { background: darken(@green, 5%); }
	.red & { background: darken(@red, 5%); }
	.purple & { background: darken(@purple, 5%); }
	.sky & { background: darken(@sky, 5%); }
	.ami & { background: darken(@ami, 5%); }
	.agef & { background: darken(@agef, 5%); }
	.baurecht & { background: darken(@baurecht, 5%); }
	.aref & { background: darken(@aref, 5%); }
	.fmentoring & { background: darken(@fmentoring, 5%); }
	.regard & { background: darken(@regard, 5%); }
}

/**
 * After element styled as a thick line
 * with the current branded color as background
 * @return {undefined}
 */
.branded-line() {
	&:after {
		content: '';
		height: 2px;
		width: 100%;
		position: absolute;
		top: 100%;
		left: 0;
		.branded-background();
	}
}
