.bs-glyphicons {
	ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
	}

	li {
		width: 100%/8;
		border: 1px solid white;
		background: @grey-light;
		padding: 10px;
		text-align: center;

		@media @max-laptop {
			width: 100%/6;
		}

		@media @max-tablet {
			width: 100%/5;
		}

		@media @mobile {
			width: 100%/4;
		}

		@media @mini-mobile {
			width: 100%/2;
		}
	}

	.glyphicon {
		display: block;
		font-size: 2em;
		margin-bottom: 0.5em;
	}
}